import React, { Component } from 'react';
import Auth from '../utils/auth';
import WithAuthentication from '../context/WithAuthentication';
import axios from 'axios';
import {storeCurrentRequestId, storeNotifications} from '../redux/actions/';
import {navigate} from 'gatsby';
import {connect} from 'react-redux';
import Loader from '../components/loader';
const API = process.env.API
class ContinueRequest extends Component {
  componentDidMount() {
    const token = Auth.getToken()
    const userInfo = Auth.getUserInfo();
    const urlParams = new URLSearchParams(window.location.search);
    const requestId = urlParams.get('id');
    axios({
      method: 'get',
      url: `${API}/users/${userInfo.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {
      this.findRequestInUserHistory(res.data, requestId)
    }).catch((error) => {
      console.log(error);
    })
  }
  findRequestInUserHistory(data, id){
    if(data.request_history){
      const findRequestHistory = data.request_history.filter((req, index)=>req.request_id === id)
      if(findRequestHistory.length > 0){
        const targetRequest = findRequestHistory[0];
        console.log('target request', targetRequest)
        this.props.storeCurrentRequestId(targetRequest);
        navigate(`/${targetRequest.department}/${targetRequest.workTypeSelected}`);
      }
      else {
        const errorMessage = {
          message: 'There was an error loading this form, please try again',
          category: 'error'
        }
        navigate('/');
        this.handleNotification(errorMessage)
      }
    }
  }
  handleNotification(message) {
    let notifications = [...this.props.notifications];
    notifications.push(message)
    this
      .props
      .storeNotifications(notifications)
  }
  render() {
    return (
      <div className="w-full h-screen bg-indigo-700 text-white flex justify-content items-center">
        <div className="w-full text-center vibrate-1">
          <h1 className="text-3xl mb-2 font-bold">Loading Request</h1>
          <p className="text-lg font-medium"><Loader/> Please Wait</p>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => ({
  ...props,
  notifications: state.notifications,
})
function mapDispatchToProps(dispatch) {
  return {
    storeCurrentRequestId: id => dispatch(storeCurrentRequestId(id)),
    storeNotifications: notifications => dispatch(storeNotifications(notifications)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(WithAuthentication(ContinueRequest));
 