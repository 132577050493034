import React from 'react';
import AuthUserContext from './AuthUserContext';
import {navigate} from 'gatsby';
import auth from '../utils/auth';
const withAuthentication = (Component) =>
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authUser: null,
      };
    }
    componentDidMount() {
      auth.getToken() !== null ? (
        this.setState({
          loading: false,
          authUser: auth.getToken(),
        })
      ) : (
        navigate('/auth')
      )
    }
    render() {
      const { authUser} = this.state;
      return (
        <AuthUserContext.Provider value={authUser}>
            {authUser&&
              <Component {...this.props} />
            }
        </AuthUserContext.Provider>
      );
    }
  }

export default withAuthentication;